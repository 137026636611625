.app-modal-main {
  width: 30vw;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  outline: none;
}

.app-divChip{
  position: absolute;
  padding: 0;
  top: -150%;
  right: 0;
  z-index: 1000;
}

.app-divChipMain{
  margin: 0 2%;
}

.app-divChipMain > span{
  font-size: 1rem;
  border-radius: 30px;
  padding: 5%;
  margin: 0 1%;
  max-width: 10vw;
  white-space: nowrap;
  color: black;
  text-align: center;
  background-color: lightgray;
  box-shadow:
  0px 31px 12px rgba(0, 0, 0, 0.13);

}

.submit-btn {
  height: 40px;
  width: 150px;
  border-radius: 30px;
  border: 0;
  background-color: var(--cl_violet);
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(69, 15, 168);
}

.student-detail-layout {
  height: 85vh;
  padding: 20px 40px;
  /* border: 1px solid red; */
  /* z-index: 2; */
}
.student-dtl-section-layout {
  display: flex;
  flex-direction: row;
}

.student-dtl-box-layout {
  display: flex;
  /* border: 1px solid rgb(17, 0, 255); */
  /* z-index: 3; */
}
.student-dtl-box-layout-basic {
  display: flex;
  width: 50%;
  margin-top: 20px;
  border: 1px solid rgb(17, 0, 255);
  /* z-index: 3; */
}

.student-dtl-box {
  flex: 2;
  border-radius: 16px;
  margin: 0px 10px;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 12px;
  /* border: 1px solid black; */
  /* z-index: 4; */
}
.student-dtl-box-finance {
  /* position: absolute; */
  flex: 2;
  /* height: 32vh; */
  border-radius: 16px;
  margin: 0px 10px;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 12px;
  /* margin-bottom: 5vh; */
}

.student-dtl-box-course {
  flex: 2;
  /* height: 20vh; */
  border-radius: 16px;
  margin: 0px 10px;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 12px;
}

.student-dtl-box-small {
  flex: 1.4;
  /* height: 32vh; */
  border-radius: 16px;
  margin: 0px 10px;
  background-color: #fff;
  padding: 10px 15px;
  font-size: 12px;
  margin-bottom: 1em;
  /* z-index: 4; */
}

.createbutton {
  /* position: absolute; */
  display: flex;
  float: right;
  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;
  /* margin-left: 31%; */
  /* bottom: 7vh;
  left: 38.5vw; */
}

.createbutton-none {
  /* position: absolute; */
  display: flex;
  /* float: right; */

  justify-content: flex-end;
  align-items: center;
  margin-top: 15px;

  /* margin-left: 44%; */

  /* bottom: 7vh;
  left: 38.5vw; */
}

.student-dtl-textinput {
  display: flex;
  align-items: center;
  margin-top: 15px;
}

.student-dtl-textinput input {
  border: 0;
  border-bottom: 1px solid gray;
  font-size: 12px;
}

.student-select-option {
  width: 100%;
  border: 0;
  margin-top: 10px;
  border-bottom: 0.5px solid grey;
  height: 28px;
}

.student-detail-finance {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  padding: 10px;
}

.student-detail-finance-box {
  font-size: 14px;
  font-weight: bold;
}

.student-detail-finance-box input {
  height: 30px;
  margin-top: 5px;
}

.section-1 {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  /*padding-top: -5px;
  */
  border-radius: 15px;
  /*border: 1px solid black;
  */
  background-color: rgb(224, 224, 224);
  width: 70%;
  justify-content: space-evenly;
}

.section-2 {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  /* padding-top: -5px; */
  /* padding-top: 45px; */
  padding-bottom: 15px;
  border-radius: 15px;
  margin-left: 10px;
  /* border: 1px solid black; */
  /* background-color: rgb(224, 224, 224); */
  width: 30%;
}


.student-main-grid-layout {
  display: grid;
  height: 70vh;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  justify-content: space-between;
  row-gap: .5em;
}

.stud-grid-item1 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 1;
  grid-row-end: span 2;
  border-radius: 16px;
  font-size: 12px;
  margin: 0px 10px;
  background-color: #fff;
  padding: 10px 15px;
}

.stud-grid-item2 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: 1;
  grid-row-end: 2;
}

.stud-grid-item3 {
  grid-column-start: 1;
  grid-column-end: 1;
  grid-row-start: 3;
  grid-row-end: 4;
}

.stud-grid-item4 {
  grid-column-start: 2;
  grid-column-end: 2;
  grid-row-start: span 2;
  grid-row-end: span 3;
}