:root {
  --cl_black: #000;
  --cl_white: #fff;
  --cl_violet: rgb(94, 33, 206);
  --cl_grey: #707070;
  --cl_blue: #0f99d0;
  --cl_green: #13cd6a;
  --cl_brown: #774835;
  --cl_red: #ff0000;
  --cl_magenta: #cd1396;
  --cl_orange: #ff8a23;
  --cl_dark_orange: #ef871f;
  --cl_light_grey: #f5f5f5;
  --cl_light_ivery: #f5f7f7;
  --cl_ivery: #e6e6e6;

  --sidebar_bgc: #2b2c30;
  --body_bgc: #f7f8fa;
}

.App {
  background-color: var(--cl_violet);
  min-height: 100vh;
  display: flex;
  font-size: calc(10px + 2vmin);
}

.app-layout {
  min-width: 1024px;
  height: 100vh;
  width: 92vw;
  background-color: rgb(235, 232, 232);
  border-top-left-radius: 40px;
  border-bottom-left-radius: 40px;
}

.app-navbar {
  border-top-left-radius: 40px;
  height: 10vh;
  background-color: #fff;
  display: flex;
  align-items: center;
}

.app-navbar img {
  height: 10vh;
  margin-left: 25px;
}


.app-navbar h1 {
  font-family: 'Fredoka One', cursive;
  margin: 0;
  margin-left: 5px;
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 1px 2px 7px rgb(31, 54, 189);
}

.app-dynamic-layout {
  height: 90vh;
  width: 92vw;
}

.navbar-main {
  height: 8vh;
  padding: 0px 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.app-nav-right {
  display: flex;
  align-items: center;
}

.app-common-btn {
  height: 34px;
  padding: 0px 20px;
  background-color: var(--cl_violet);
  border: 0;
  border-radius: 30px;
  color: #fff;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  cursor: pointer;
}

.add-on-course-btn {
  height: 28px;
  font-size: 12px;
  padding: 0px 8px;
  background-color: var(--cl_violet);
  border: 0;
  border-radius: 30px;
  color: #fff;
  font-weight: bolder;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
  cursor: pointer;
}
.add-on-course-btn:hover {
  background-color: #0d8859;
}

.app-btn-green:hover {
  background-color: var(--cl_violet);
}

.app-btn-green {
  background-color: #18b87b;
}

.app-common-btn:hover {
  background-color: #0d8859;
}

.app-nav-back-btn {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 20px;
  color: #000;
  text-decoration: none;
}

.app-nav-back-btn:hover {
  background-color: rgb(160, 160, 160);
}

.app-nav-searchbar {
  height: 34px;
  width: 250px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border: 1px solid grey;
  border-radius: 10px;
  padding: 0px 10px;
  margin: 0px 10px;
}

.app-nav-searchbar input {
  border: 0;
  width: 85%;
}

.app-main-list-layout {
  padding: 0px 40px;
  height: 77vh;
}

.app-table-layout {
  position: relative;
  width: 100%;
}

.app_table_header {
  display: flex;
  width: 100%;
  padding: 0px 20px;
}

.app_table_header_content {
  max-width: 28vw;
  width: 100%;
  padding: 8px 0px;
  font-size: 16px;
  font-weight: bolder;
  background: rgb(248, 248, 250);
}

.app_table_main {
  width: 100%;
  justify-content: space-around;
  background: white;
  padding: 0px 20px;
  border-collapse: collapse;
  border-radius: 10px;
  position: relative;
}

.app_table_main tr:nth-child(even) {
  background-color: #f2f2f2;
}

.app_table_main td,
.app_table_main th {
  border: 1px solid #ddd;
  padding: 8px;
}

.app_table_tbody {
  font-size: 12px;
  padding: 8px 0px;
  max-width: 28px;
  min-width: 28px;
  text-align: center;
  position: relative;
}

.app_table_tbody tr {
  height: 40px;
}

.app_table_header {
  font-weight: "bold";
  font-size: 16px;
}

td {
  padding: 0;
  margin: 0;
}

.disabled-btn {
  opacity: 0.5;
  pointer-events: none;
}
.disabled-btn-none {
  opacity: 0.3;
  pointer-events: none;
  /* display: none; */
}

.app-modal-main {
  width: 30vw;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  outline: none;
}

.swal-btn {
  border: 0;
  height: 35px;
  width: 130px;
  margin: 5px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}

.btn-danger {
  background-color: red;
}

.btn-cancel {
  background-color: gray;
}

.submit-btn {
  height: 40px;
  width: 150px;
  border-radius: 30px;
  border: 0;
  background-color: var(--cl_violet);
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(69, 15, 168);
}

.bg-test {
  background-color: red;
}

/* @me screen and (max-width : 850px){

  .App{

  display: none;

  }

  
}

/*  ####################################### LARGE TABLET STYLING ####################################### */

/* @media screen and (max-width :1000px) {


.App{

background-color: white;
justify-content: center;

}
  
}  */
