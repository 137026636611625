.app-modal-main {
  width: 30vw;
  background-color: #fff;
  border-radius: 30px;
  padding: 20px;
  outline: none;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
}

.app-modal-main::-webkit-scrollbar {
  display: none;
}

.submit-btn {
  height: 40px;
  width: 150px;
  border-radius: 30px;
  border: 0;
  background-color: var(--cl_violet);
  color: #fff;
  font-weight: bold;
  margin-top: 20px;
  cursor: pointer;
}

.submit-btn:hover {
  background-color: rgb(69, 15, 168);
}

/* ##################### FILTER ################## */

.content_filter_student {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  font-size: 16px;
  font-weight: bolder;
  /* left: 40%; */
  /* right: 25%; */
  justify-content: space-evenly;
  /* border: 1px solid yellow; */
  border-radius: 25px;
  background-color: rgb(107, 202, 123);
  width: 4vw;
  height: 3vh;
  min-width: 4vw;
  padding: 2px 8px 2px 8px;
  /* color: white; */
}
.filter_layout {
  position: absolute;
  height: 35vh;
  width: 15em;
  background-color: white;
  /* border: 1px solid black; */
  box-shadow: 0.5px 0.5px 3px 2px rgb(218, 216, 216);

  z-index: 4;
  margin-top: 42vh;
  /* padding-top: 30px; */
  overflow: hidden;
  /* transition: 3s; */
}
