.login-container {
  display: flex;
  background-color: #faf5fd;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.login-layout {
  display: flex;
  /* height: 60vh; */
  width: 30vw;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  align-items: center;
  flex-direction: row;
  box-shadow: 4px 4px 100px 30px #eef0fb;
  border-radius: 25px;
}

.login-form-layout {
  display: flex;
  /* height: 50vh; */
  width: 25vw;
  padding: 10px 2px 20px 2px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 4%;
}

.login-head {
  padding: 20px 0;
  font-size: 30px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  text-align: center;
}

.login-head img {
  height: 20vh;
  object-fit: contain;
}

.login-head h1 {
  font-family: 'Fredoka One', cursive;
  margin: 0;
  margin-top: -20px;
  font-size: 1.2rem;
  color: #ffffff;
  text-shadow: 1px 2px 7px rgb(31, 54, 189);
}

.login-head p {
  font-size: 16px;
  margin-top: 10px;
  font-weight: bold;
  opacity: 0.5;
}


.login-form-div {
  display: flex;
  width: 25vw;
  height: 30vh;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
}

.login-form-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  height: 90%;
}

.login-Username-input {
  height: 5vh;
  background-color: rgba(234, 236, 236, 0.5);
  border: 1px solid black;
  color: black;
  border-radius: 25px;
  margin-bottom: 4%;
  box-shadow: 1px 1px 0.5px 1px rgb(222, 185, 247);
}
.login-Username-input:hover {
  background-color: rgba(207, 211, 211, 0.5);
}
.login-form-btn {
  padding: 10px;
  border-radius: 25px;
  width: 6vw;
  font-size: 18px;
  font-weight: bolder;
  cursor: pointer;
  background-color: #7824e2;
  color: white;
  box-shadow: 2px 2px 40px 2px #e0c2f1;
}

.login-form-btn:hover {
  background-color: rgb(170, 111, 247);
}

.login-logo {
  width: 14vw;
}

.input-field {
  max-width: 380px;
  width: 100%;
  background-color: #faf3fdfd;
  border: 0.5px solid rgb(241, 236, 245);
  height: 55px;
  border-radius: 55px;
  display: flex;
  flex-direction: row;
  padding: 0 0.4rem;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 50px 10px #eef0fb;
}
.icons {
  text-align: center;
  color: #7824e2;
  transition: 0.5s;
  font-size: 1.5rem;
}
.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 1.1rem;
  color: #333;
  padding-left: 14px;
  height: 4.5vh;
}
.input-field input::placeholder {
  color: rgb(183, 183, 184);
  font-weight: bolder;
}
.input-field:hover {
  border: 0.1px solid rgb(232, 211, 241);
}


